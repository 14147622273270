<template lang="pug">
    section.call_to_action
        .grid
            .action--description
                div
                    .margin-bottom--xl
                        base-heading(title="Contactanos" alt)
                        p ¿Tienes un problema de izaje? ¡Nosotros te ayudamos!, Te ofrecemos renta de grúas con personal capacitado por hora, día y semana, los 365 días del año.
                    base-link(:route="{ name: 'contact' }" title="Contactar" alt)
            div
                .polygon.background
                    svg
                        use(xlink:href="#icon-polygon-frame")
                figure(v-lazyload).image
                    img(data-src="/img/img-callToAction.png" data-srcset="" alt="Titan")
</template>

<script>
export default {
    name: 'include_callToAction'
}
</script>